import { Injectable } from '@angular/core';


import { ipcRenderer, webFrame, remote, shell, desktopCapturer, clipboard, nativeImage } from 'electron';
import { Client } from 'ssh2';
import * as childProcess from 'child_process';
import * as fs from 'fs-jetpack';
import * as os from 'os';
import * as Shell from 'node-powershell';
import * as notifier from 'node-notifier';


@Injectable({
  providedIn: 'root'
})
export class NgxElectronPlusService {
  ipcRenderer: typeof ipcRenderer;
  webFrame: typeof webFrame;
  remote: typeof remote;
  childProcess: typeof childProcess;
  fs: typeof fs;
  shell: typeof shell;
  os: typeof os;
  powershell: typeof Shell;
  process: typeof process;
  desktopCapturer: typeof desktopCapturer;
  clipboard: typeof clipboard;
  screen: typeof screen;
  ssh: typeof Client;
  nativeImage: typeof nativeImage;
  notifier: typeof notifier;

  get isElectron(): boolean {
    return !!(window && window.process && window.process.type);
  }

  constructor() {
    // Conditional imports
    if (this.isElectron) {
      this.ipcRenderer = window.require('electron').ipcRenderer;
      this.webFrame = window.require('electron').webFrame;
      this.remote = window.require('electron').remote;
      this.os = window.require('os');
      this.powershell = window.require('node-powershell');
      this.childProcess = window.require('child_process');
      this.fs = window.require('fs-jetpack');
      this.shell = window.require('electron').shell;
      this.desktopCapturer = window.require('electron').desktopCapturer;
      this.clipboard = window.require('electron').clipboard;
      this.screen = window.screen;
      this.nativeImage = window.require('electron').nativeImage;
      this.ssh = window.require('ssh2').Client;
      this.notifier = window.require('node-notifier');
    }
  }

}
