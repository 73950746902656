/*
 * Public API Surface of ngx-electron-plus
 */


import { NgModule } from '@angular/core';
import { NgxElectronPlusService } from './lib/ngx-electron-plus.service';


export * from './lib/ngx-electron-plus.service';

@NgModule({
    declarations: [],
    exports: [],
    providers: [ NgxElectronPlusService ]
})
export class NgxElectronPlusModule {

}